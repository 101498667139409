@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';

.table-filter {
    margin-top: 15px;

    .table-filter-top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        align-items: center;

        .table-filter-left {
            padding: 5px;
            width: 100%;
            overflow: auto;
            order: 1;

            .text-input-div {
                width: 250px;
                max-width: 100%;
            }
        }

        .table-filter-right {
            order: 2;
            display: flex;
            justify-content: flex-end;

        }
    }

    .table-filter-content {
        width: 100%;
        overflow: auto;

        table {
            width: 100%;
            border-collapse: collapse;

            td,
            th {
                border: 1px solid #ddd;
                padding: 5px 8px;
                font-size: 14px;
            }

            tr {
                &:nth-child(even) {
                    background-color: #f2f2f2;
                }

                &:hover {
                    background-color: #ddd;
                }
            }

            th {
                padding-top: 12px;
                padding-bottom: 12px;
                text-align: left;
                background-color: #272727;
                color: white;
                text-align: center;
            }


        }
    }

    .table-filter-bottom {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        .table-filter-left {
            order: 1;

            .page-row {
                label {
                    font-size: 14px;
                    font-weight: 500;
                    margin-right: 10px;
                }
            }
        }

        .table-filter-right {
            order: 2;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            align-items: center;

            .page-numbers {
                font-size: 14px;
                font-weight: 500;

            }

            .pagination-buttons {
                display: flex;
                gap: 7px;

                button {
                    @include normalButton();
                    background-color: rgb(172, 172, 172);
                    color: rgb(17, 17, 17);
                }

                .hide {
                    background-color: rgb(223, 223, 223);
                    color: rgb(168, 168, 168);
                    cursor: default;
                }
            }
        }
    }


    .text-input-div {
        position: relative;
        width: 100%;
        margin: 2px 0;

        input,
        select {
            width: 100%;
            font-size: 13px;
            font-weight: 600;
            padding: 10px 15px;
            outline: none;
            border: none;
            border: 1px rgb(173, 173, 173) solid;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            &:focus {
                outline: 1px solid $blue_color;
                border-color: $blue_color;
            }

            &:focus~label {
                color: $blue_color;


            }

            &:focus~label,
            &:valid~label {
                top: -7px;
                left: 10px;
                font-size: 12px;
                font-weight: 500;
                max-width: 85%;
                padding: 0px 5px;
                width: auto;

            }
        }

        label {
            font-size: 13px;
            font-weight: 600;
            position: absolute;
            top: 8px;
            left: 15px;
            color: rgb(85, 85, 85);
            transition: 0.1s ease-in-out;
            -webkit-transition: 0.1s ease-in-out;
            -moz-transition: 0.1s ease-in-out;
            -ms-transition: 0.1s ease-in-out;
            -o-transition: 0.1s ease-in-out;
            background-color: white;
            width: 85%;
            padding: 3px 0;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
        }

        .icon {
            position: absolute;
            right: 10px;
            bottom: 9px;
            cursor: pointer;
        }
    }


    @media screen and (max-width:600px) {
        .table-filter-top {
            grid-template-columns: 1fr;

            .table-filter-left {
                order: 2;
                display: flex;
                justify-content: center;

                .text-input-div {
                    width: 250px;
                    max-width: 100%;
                }
            }

            .table-filter-right {
                order: 1;
            }
        }

        .table-filter-bottom {
            grid-template-columns: 1fr;
            justify-items: center;

            .table-filter-left {
                order: 2;
                margin-top: 10px;

            }

            .table-filter-right {
                order: 1;
            }
        }
    }
}