@mixin normalButton() {
    border: none;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 10px 15px;
    background-color: black;
    cursor: pointer;

    &:hover {
        opacity: .8;
    }

    &:focus {
        opacity: 1;
    }
}

.container {
    padding-left: 5%;
    padding-right: 5%;
}


// input
input[type="date"] {
    /* Reset default styles */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: none;
    min-height: 7px;
}


// select {
//     /* Reset default styles */
//     appearance: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     border: none;
//     background: none;
// }

.loading-icon {
    svg {
        margin-bottom: -1px;
        animation: iconLoading 1.2s linear infinite;
        -webkit-animation: iconLoading 1.2s linear infinite;
    }

    @keyframes iconLoading {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}