.model {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;

    .boarder {
        display: grid;
        place-content: center;
        width: 100%;
        height: 100vh;
        padding: 15px;
        position: relative;

        .shadow {
            background-color: rgba(187, 187, 187, 0.274);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100vh);
            backdrop-filter: blur(3px);
        }

        .box {
            background-color: white;
            width: 350px;
            max-width: 100%;
            height: auto;
            max-height: 80vh;
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            z-index: 6;

            .header {
                padding: 10px 15px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid gray;
                align-items: center;
                .close-icon{
                    cursor: pointer;
                }

            }

            .content {
                padding: 10px 15px;
            }
        }
    }
}