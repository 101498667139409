@import '../../assets/scss/model.scss';

.materials-div {
    .container {
        .bottom {
            margin-top: 20px;

            .boarder {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                gap: 12px;
                box-sizing: border-box;

                .button-div {
                    button {
                        @include normalButton();
                        width: 100%;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        font-size: 12px;
                    }
                }
            }
        }
    }


    @media screen and (max-width:700px) {
        .container {
            .bottom {
                .boarder {
                    grid-template-columns: 50% 50%;
                }
            }
        }
    }

    @media screen and (max-width:450px) {
        .container {
            .bottom {
                .boarder {
                    grid-template-columns: 100%;
                }
            }
        }
    }
}