@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';

.test-report-div .container {
    .content {


        .add-button {
            @include normalButton();
            background-color: $green_color;
            font-size: 13px;
            display: flex;

            svg {
                font-size: 18px;
                margin: 0;
                margin-right: 5px;
            }
        }


        .table-div {
            width: 100%;
            overflow: auto;

            .no-data {
                width: 100%;
                height: 80vh;
                display: grid;
                place-items: center;
            }

            table {

                div {
                    display: flex;
                    justify-content: center;

                    button {
                        margin: 0 5px;
                        @include normalButton();
                        font-size: 18px;
                        padding: 5px 15px 2px 15px;
                    }

                    .edit {
                        background-color: $edit_color;

                    }

                    .create {
                        background-color: $green_color;
                    }

                    .delete {
                        background: $red_color;
                    }
                }

            }
        }
    }
}