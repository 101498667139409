@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';

.add-edit-div {
    .inputs {
        .input-div {
            margin-bottom: 15px;
            position: relative;

            .readonly{
                cursor: not-allowed;
                background-color: gainsboro;
            }

            input,
            select {
                width: 100%;
                padding: 10px 15px;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                outline: none;
                border: 1px solid black;
                margin-top: 5px;
                font-weight: 500;
                font-size: 14px;
            }

            label {
                font-size: 13px;
                font-weight: 500;
            }

            .icon {
                position: absolute;
                right: 10px;
                bottom: 9px;
                cursor: pointer;
            }
        }

        .button-div {
            button {
                @include normalButton();
                width: 100%;
                background-color: $blue_color;
            }
        }
    }
}