@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';

.dynamic-text {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    .dynamic-form-div {
        order: 1;

        form {

            .dtl-input-div {
                position: relative;
                width: 100%;
                margin: 2px 0;

                textarea {
                    width: 100%;
                    min-width: 100%;
                    max-width: max-content;
                    min-height: 50px;
                    max-height: 200px;
                    font-size: 13px;
                    font-weight: 600;
                    padding: 10px 15px;
                    outline: none;
                    border: none;
                    border: 1px rgb(173, 173, 173) solid;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;

                    &:focus {
                        outline: 1px solid $blue_color;
                        border-color: $blue_color;
                    }

                    &:focus~label {
                        color: $blue_color;


                    }

                    &:focus~label,
                    &:valid~label {
                        top: -7px;
                        left: 10px;
                        font-size: 12px;
                        font-weight: 500;
                        width: auto;
                        max-width: 85%;
                        padding: 0px 5px;
                    }
                }

                label {
                    font-size: 13px;
                    font-weight: 600;
                    position: absolute;
                    top: 8px;
                    left: 15px;
                    color: rgb(85, 85, 85);
                    transition: 0.1s ease-in-out;
                    -webkit-transition: 0.1s ease-in-out;
                    -moz-transition: 0.1s ease-in-out;
                    -ms-transition: 0.1s ease-in-out;
                    -o-transition: 0.1s ease-in-out;
                    background-color: white;
                    width: 85%;
                    padding: 3px 0;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            .botton-div {
                display: flex;
                justify-content: flex-end;

                button {
                    @include normalButton();
                    font-size: 12px;
                    min-width: 150px;
                    background-color: $green_color;
                }
            }
        }
    }

    .text-div {
        order: 2;
        background-color: rgba(223, 223, 223, 0.637);
        padding: 15px;
        height: fit-content;
        max-height: 240px;
        overflow: auto;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;

        p {
            font-size: 13px;
            text-align: center;
            color: gray;
        }

        ol {
            padding: 0 10px 0 20px;

            li {
                font-size: 12px;

                .list {
                    display: flex;

                    div {
                        &:first-child {
                            font-size: 12px;
                            font-weight: 500;
                            margin: 5px 0;
                            text-align: justify;
                        }

                        &:last-child {
                            font-size: 15px;
                            padding: 5px;
                            cursor: pointer;
                            height: fit-content;
                        }
                    }
                }
            }
        }
    }

    .mob {
        display: block;
    }
}

@media screen and (max-width:800px) {
    .dynamic-text {
        grid-template-columns: 1fr;

        .dynamic-form-div {
            order: 2;
        }

        .text-div {
            order: 1;
        }

        .mob {
            display: none;
        }
    }

}