@import '../../assets/scss/model.scss';
@import '../../assets/scss/color.scss';

.quotation-div {
    .container {
        .bottom {
            margin-top: 20px;

            .boarder {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                gap: 12px;
                box-sizing: border-box;

                .button-div {
                    button {
                        @include normalButton();
                        width: 100%;
                        padding-top: 20px;
                        padding-bottom: 20px;
                        font-size: 12px;
                    }
                }
            }
        }
    }


    @media screen and (max-width:700px) {
        .container {
            .bottom {
                .boarder {
                    grid-template-columns: 50% 50%;
                }
            }
        }
    }

    @media screen and (max-width:450px) {
        .container {
            .bottom {
                .boarder {
                    grid-template-columns: 100%;
                }
            }
        }
    }
}

// Success
.success-div {
    width: 100%;
    height: calc(100vh - 60px);
    display: grid;
    place-content: center;
    color: $green_color;

    .box {
        text-align: center;

        .icon-div {
            font-size: 50px;
        }

        h2 {
            font-size: 24px;
        }

        h4 {
            font-size: 14px;
            color: black;
        }

        .button-div {
            margin-top: 15px;

            button {
                @include normalButton();
                background-color: $blue_color;
                margin: 0 5px;
                &:last-child{
                    background-color: black;
                }
            }
        }
    }
}