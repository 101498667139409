@import '../../assets/scss/color.scss';
@import '../../assets/scss/model.scss';

.form-div {
    padding-bottom: 15px;

    form {
        .section-div {
            margin-top: 15px;

            .header {
                h5 {
                    color: rgb(95, 95, 95);
                    padding: 7px 0;
                }
            }

            .forms {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 15px;

                .sign-canvas-div {
                    position: relative;
                    width: 100%;
                    margin: 2px 0;
                    margin-top: 15px;

                    .canvas-div {
                        width: 100%;
                        font-size: 13px;
                        font-weight: 600;
                        padding: 10px 15px;
                        outline: none;
                        border: none;
                        border: 1px rgb(173, 173, 173) solid;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;
                    }

                    label {

                        position: absolute;
                        color: rgb(85, 85, 85);
                        transition: 0.1s ease-in-out;
                        -webkit-transition: 0.1s ease-in-out;
                        -moz-transition: 0.1s ease-in-out;
                        -ms-transition: 0.1s ease-in-out;
                        -o-transition: 0.1s ease-in-out;
                        background-color: white;
                        top: -7px;
                        left: 10px;
                        font-size: 12px;
                        font-weight: 500;
                        width: auto;
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 85%;
                        padding: 0px 5px;
                    }
                }


                .nor-input-div {
                    position: relative;
                    width: 100%;
                    margin: 2px 0;

                    input,
                    select {
                        width: 100%;
                        font-size: 13px;
                        font-weight: 600;
                        padding: 10px 15px;
                        outline: none;
                        border: none;
                        border: 1px rgb(173, 173, 173) solid;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;

                        &:focus {
                            outline: 1px solid $blue_color;
                            border-color: $blue_color;
                        }

                        &:focus~label {
                            color: $blue_color;


                        }

                        &:focus~label,
                        &:valid~label {
                            top: -7px;
                            left: 10px;
                            font-size: 12px;
                            font-weight: 500;
                            max-width: 85%;
                            padding: 0px 5px;
                            width: auto;

                        }
                    }

                    label {
                        font-size: 13px;
                        font-weight: 600;
                        position: absolute;
                        top: 8px;
                        left: 15px;
                        color: rgb(85, 85, 85);
                        transition: 0.1s ease-in-out;
                        -webkit-transition: 0.1s ease-in-out;
                        -moz-transition: 0.1s ease-in-out;
                        -ms-transition: 0.1s ease-in-out;
                        -o-transition: 0.1s ease-in-out;
                        background-color: white;
                        width: 85%;
                        padding: 3px 0;
                        display: inline-block;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }

                .check-input-div {
                    position: relative;
                    width: 100%;
                    margin: 2px 0;

                    input {
                        outline: none;
                       
                    }

                    label {
                        font-size: 13px;
                        font-weight: 600;
                        color: rgb(85, 85, 85);
                        margin-left: 5px;
                    }
                }
            }

            @media screen and (max-width:800px) {
                .forms {
                    grid-template-columns: 1fr;
                }
            }

            @media only screen and (max-width: 767px) and (orientation: landscape) {
                .label {
                    font-size: 13px;
                    /* Adjust the font size as needed */
                }
            }
        }

        .button-div {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;


            button {
                @include normalButton();
                min-width: 100px;
                margin: 0 5px;
            }

            .skip {
                background-color: gray;
            }

            .next {
                background-color: $blue_color;
            }

            .submit {
                background-color: $green_color;
                font-weight: 700;
                min-width: 150px;
            }
        }
    }

    .loading-div {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(193, 253, 255, 0.233);
        z-index: 5;
        left: 0;
        top: 0;
        backdrop-filter: blur(3px);
        display: grid;
        place-content: center;

       >div {
            width: 130px;
            background-color: white;
            border-radius: 7px;
            -webkit-border-radius: 7px;
            -moz-border-radius: 7px;
            -ms-border-radius: 7px;
            -o-border-radius: 7px;
            display: grid;
            place-content: center;
        }
    }
}