.spin-with-message {
    .spinner-body {
        width: 150px;
        padding: 15px 20px;
        text-align: center;

        .load-div {
            font-size: 40px;
            color: rgb(92, 92, 92);
        }

        .spin-div {
            svg {
                animation: iconLoading 1.5s linear infinite;
                -webkit-animation: iconLoading 1.5s linear infinite;
}

            @keyframes iconLoading {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        .message {
            p {
                font-size: 14px;
                font-weight: 500;
                color: gray;
            }
        }
    }
}